import { getBanners } from '@/shared/opa-api';
import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';

const BANNERS_TYPE = {
  INFO: 'info',
  ACTION: 'action',
  OUTAGE: 'outage',
  WARNING: 'warning',
};

const BANNER_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const normalizeType = (banner: any) => {
  switch (banner.type) {
    case BANNERS_TYPE.INFO:
      return BANNER_TYPES.INFO;
    case BANNERS_TYPE.OUTAGE:
      return BANNER_TYPES.ERROR;
    case BANNERS_TYPE.ACTION:
      return BANNER_TYPES.SUCCESS;
    case BANNERS_TYPE.WARNING:
      return BANNER_TYPES.WARNING;
    default:
      return BANNER_TYPES.INFO;
  }
};

const getPriority = (banner: any) => {
  switch (banner.type) {
    case BANNERS_TYPE.INFO:
      return 0;
    case BANNERS_TYPE.OUTAGE:
      return 3;
    case BANNERS_TYPE.ACTION:
      return 1;
    case BANNERS_TYPE.WARNING:
      return 2;
    default:
      return 0;
  }
};

const isDeletable = (banner: any) => banner.type !== BANNERS_TYPE.OUTAGE;

const normalize = (banner: any) => ({
  ...banner,
  type: normalizeType(banner),
  priority: getPriority(banner),
  isDeletable: isDeletable(banner),
});

const sort = (a: any, b: any) => b.priority - a.priority;

export const BANNERS_GETTERS = {
  RECENT: 'recent',
  CURRENT: 'current',
  INDEX: 'index',
  BANNERS_SHOW: 'bannersShow',
};

export const BANNERS_ACTIONS = {
  REMOVE_CURRENT_BANNER: 'removeCurrentBanner',
  FETCH_BANNERS: 'fetchBanners',
  CLOSE_BANNERS: 'closeBanners',
  NEXT_BANNER: 'nextBanner',
  PREV_BANNER: 'prevBanner',
  TO_START: 'toStart',
  TO_END: 'toEnd',
};

const BANNERS_MUTATIONS = {
  REMOVE_CURRENT_BANNER: 'removeCurrentBanner',
  SET_RECENT: 'setBanner',
  SET_INDEX: 'setIndex',
  SET_CURRENT: 'setCurrent',
  SET_BANNERS_SHOW_FLAG: 'setBannersShowFlag',
};

export default {
  namespaced: true,
  state: {
    recent: [],
    index: 0,
    current: undefined,
    showBanners: false,
  },
  getters: {
    [BANNERS_GETTERS.RECENT]: (state: any) => state.recent,
    [BANNERS_GETTERS.CURRENT]: (state: any) => state.current,
    [BANNERS_GETTERS.INDEX]: (state: any) => state.index,
    [BANNERS_GETTERS.BANNERS_SHOW]: (state: any) => state.showBanners,
  },
  actions: {
    [BANNERS_ACTIONS.CLOSE_BANNERS]: (context: any, flag: boolean) => {
      context.commit(BANNERS_MUTATIONS.SET_BANNERS_SHOW_FLAG, flag);
    },
    [BANNERS_ACTIONS.REMOVE_CURRENT_BANNER]: (context: any, id: string) => {
      context.commit(BANNERS_MUTATIONS.REMOVE_CURRENT_BANNER);
      const index = 0;
      context.commit(BANNERS_MUTATIONS.SET_INDEX, index);
      context.commit(BANNERS_MUTATIONS.SET_CURRENT, context.getters.recent[index]);
    },
    [BANNERS_ACTIONS.FETCH_BANNERS]: async (context: any) => {
      await apollo.apolloClient
        .query({
          query: gql`
            query {
              fetchBanners(apmId: "engprt") {
                id
                apmId
                content
                name
                type
                publishedAt
                enabled
              }
            }
          `,
        })
        .then((response) => {
          const banners = response.data.fetchBanners
            .filter((banner: any) => banner.enabled)
            .map(normalize)
            .sort(sort);
          context.commit(BANNERS_MUTATIONS.SET_RECENT, banners);

          const current = context.getters.current || {};
          if (!banners.find((banner: any) => banner.id === current.id)) {
            context.commit(BANNERS_MUTATIONS.SET_CURRENT, context.getters.recent[0]);
          }
          if (banners.length) {
            context.commit(BANNERS_MUTATIONS.SET_BANNERS_SHOW_FLAG, true);
          }
        });
    },
    [BANNERS_ACTIONS.NEXT_BANNER]: async (context: any) => {
      const { index } = context.getters;
      context.commit(BANNERS_MUTATIONS.SET_INDEX, index + 1);
      context.commit(BANNERS_MUTATIONS.SET_CURRENT, context.getters.recent[index + 1]);
    },
    [BANNERS_ACTIONS.PREV_BANNER]: async (context: any) => {
      const { index } = context.getters;
      context.commit(BANNERS_MUTATIONS.SET_INDEX, index - 1);
      context.commit(BANNERS_MUTATIONS.SET_CURRENT, context.getters.recent[index - 1]);
    },
    [BANNERS_ACTIONS.TO_START]: async (context: any) => {
      const index = 0;
      context.commit(BANNERS_MUTATIONS.SET_INDEX, index);
      context.commit(BANNERS_MUTATIONS.SET_CURRENT, context.getters.recent[index]);
    },
    [BANNERS_ACTIONS.TO_END]: async (context: any, getters: any) => {
      const index = getters.recent.length;
      context.commit(BANNERS_MUTATIONS.SET_INDEX, index - 1);
      context.commit(BANNERS_MUTATIONS.SET_CURRENT, context.getters.recent[index - 1]);
    },
  },
  mutations: {
    [BANNERS_MUTATIONS.SET_BANNERS_SHOW_FLAG]: (state: any, flag: string) => {
      state.showBanners = flag;
    },
    [BANNERS_MUTATIONS.REMOVE_CURRENT_BANNER]: (state: any, id: string) => {
      if (state.current.isDeletable) {
        state.deleted.push(id);
      } else {
        state.closed.push(id);
      }
    },
    [BANNERS_MUTATIONS.SET_RECENT]: (state: any, banners: any) => {
      state.recent = banners;
    },
    [BANNERS_MUTATIONS.SET_CURRENT]: (state: any, banner: any) => {
      state.current = banner;
    },
    [BANNERS_MUTATIONS.SET_INDEX]: (state: any, index: any) => {
      state.index = index;
    },
  },
};
